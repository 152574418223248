import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{attrs:{"subheader":"","two-line":""}},[_c(VSubheader,{attrs:{"inset":""}},[_vm._v(" Folders ")]),_vm._l((_vm.folders),function(folder){return _c(VListItem,{key:folder.title},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"size":"26"}},[_vm._v(" "+_vm._s(_vm.mdiFolderOutline)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(folder.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(folder.subtitle)}})],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.mdiInformationOutline)+" ")])],1)],1)],1)}),_c(VDivider,{attrs:{"inset":""}}),_c(VSubheader,{attrs:{"inset":""}},[_vm._v(" Files ")]),_vm._l((_vm.files),function(file){return _c(VListItem,{key:file.title},[_c(VListItemAvatar,{attrs:{"color":file.color}},[_c(VIcon,{attrs:{"dark":"","size":"26","color":"white"},domProps:{"textContent":_vm._s(file.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(file.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(file.subtitle)}})],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.mdiInformationOutline)+" ")])],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }