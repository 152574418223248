import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.mdiHomeOutline))])],1),_c(VListItemTitle,[_vm._v("Home")])],1),_c(VListGroup,{attrs:{"value":true,"prepend-icon":_vm.mdiAccountCircleOutline},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v("Users")])]},proxy:true}])},[_c(VListGroup,{attrs:{"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Admin")])],1)]},proxy:true}])},_vm._l((_vm.admins),function(ref,i){
var title = ref[0];
var icon = ref[1];
return _c(VListItem,{key:i,attrs:{"link":""}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(title)}}),_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(icon)}})],1)],1)}),1),_c(VListGroup,{attrs:{"no-action":"","value":true,"sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Actions")])],1)]},proxy:true}])},_vm._l((_vm.cruds),function(ref,i){
var title = ref[0];
var icon = ref[1];
return _c(VListItem,{key:i,attrs:{"link":""}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(title)}}),_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(icon)}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }