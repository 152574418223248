import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{attrs:{"two-line":""}},[_c(VListItemGroup,{attrs:{"active-class":"pink--text","multiple":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.items),function(item,index){return [_c(VListItem,{key:item.title,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"primary--text",domProps:{"textContent":_vm._s(item.title)}}),_c(VListItemSubtitle,{staticClass:"grey--text",domProps:{"textContent":_vm._s(item.headline)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(item.subtitle)}})],1),_c(VListItemAction,[_c(VListItemActionText,{domProps:{"textContent":_vm._s(item.action)}}),(!active)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.mdiStarOutline)+" ")]):_c(VIcon,{attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.mdiStar)+" ")])],1)]}}],null,true)}),(index < _vm.items.length - 1)?_c(VDivider,{key:index}):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }