import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{attrs:{"three-line":""}},[_vm._l((_vm.items),function(item,index){return [(item.header)?_c(VSubheader,{key:item.header,domProps:{"textContent":_vm._s(item.header)}}):(item.divider)?_c(VDivider,{key:index,attrs:{"inset":item.inset}}):_c(VListItem,{key:item.title},[_c(VListItemAvatar,[_c(VImg,{attrs:{"src":item.avatar}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VListItemSubtitle,[_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.subtitle.name)+" ")]),_vm._v(" — "+_vm._s(item.subtitle.desc)+" ")])],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }