import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{attrs:{"flat":"","subheader":"","three-line":""}},[_c(VSubheader,[_vm._v("General")]),_c(VListItemGroup,{attrs:{"multiple":""},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}},[_c(VListItem,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Notifications")]),_c(VListItemSubtitle,[_vm._v("Notify me about updates to apps or games that I downloaded")])],1)]}}])}),_c(VListItem,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Sound")]),_c(VListItemSubtitle,[_vm._v("Auto-update apps at any time. Data charges may apply")])],1)]}}])}),_c(VListItem,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Auto-add widgets")]),_c(VListItemSubtitle,[_vm._v("Automatically add home screen widgets when downloads complete")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }